import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PartDialogQuery } from '../../../../../../generated/graphql';
import { ValueText } from './valueText';

type PartVersion = PartDialogQuery['parts'][0]['partVersions'][0];

interface Props {
  partVersions: PartVersion[]
}

export function PartVersionsTable(props: Props) {
  const { partVersions } = props;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>versionId</TableCell>
            <TableCell>partValidity</TableCell>
            <TableCell>preSeriesNumber</TableCell>
            <TableCell>sharedTechFlag</TableCell>
            <TableCell>status</TableCell>
            <TableCell>createdAt</TableCell>
            <TableCell>lastModified</TableCell>
            <TableCell>releaseDate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partVersions.map((version, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{ValueText(version.versionId)}</TableCell>
              <TableCell>{ValueText(version.partValidity)}</TableCell>
              <TableCell>{ValueText(version.preSeriesNumber)}</TableCell>
              <TableCell>{ValueText(version.sharedTechFlag)}</TableCell>
              <TableCell>{ValueText(version.status)}</TableCell>
              <TableCell>{ValueText(version.createdAt)}</TableCell>
              <TableCell>{ValueText(version.lastModified)}</TableCell>
              <TableCell>{ValueText(version.releaseDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
