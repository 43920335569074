/* eslint-disable max-len */
import List from '@mui/material/List';
import React from 'react';
import { SoftwarePartDialogQuery } from '../../../../../../generated/graphql';
import { FieldValue } from '../../common/FieldValue';

type SoftwarePart = SoftwarePartDialogQuery['softwareParts'][0];

interface Props {
  softwarePart?: SoftwarePart
}

export function FieldList({ softwarePart }: Props) {
  return (
    <List disablePadding>
      <FieldValue name="partNumber" value={softwarePart?.partNumber}/>
      <FieldValue name="name" value={softwarePart?.name}/>
      <FieldValue name="assignmentNumber" value={softwarePart?.assignmentNumber}/>
      <FieldValue name="project" value={softwarePart?.project}/>
      <FieldValue name="partType" value={softwarePart?.partType}/>
      <FieldValue name="partTypeDescription" value={softwarePart?.partTypeDescription}/>
      {/* <FieldValue name="docDuty" value={softwarePart?.docDuty}/> */}
      {/* <FieldValue name="unitOfMeasure" value={softwarePart?.unitOfMeasure}/> */}
      <FieldValue name="kuResponsible" value={softwarePart?.kuResponsible}/>
      <FieldValue name="taskNumber" value={softwarePart?.taskNumber}/>
      <FieldValue name="taskStatus" value={softwarePart?.taskStatus}/>
      <FieldValue name="costCarrier" value={softwarePart?.costCarrier}/>
      <FieldValue name="designOwner" value={softwarePart?.designOwner}/>
      <FieldValue name="description" value={softwarePart?.description}/>
      {/* <FieldValue name="softwarePartType" value={softwarePart?.softwarePartType}/> */}
      {/* <FieldValue name="softwarePartTypeDescription" value={softwarePart?.softwarePartTypeDescription}/> */}
      {/* <FieldValue name="unit" value={softwarePart?.unit}/> */}
      {/* <FieldValue name="aWeight" value={softwarePart?.aWeight}/> */}
      {/* <FieldValue name="weightType" value={softwarePart?.weightType}/> */}
      {/* <FieldValue name="iWeight" value={softwarePart?.iWeight}/> */}
      {/* <FieldValue name="quantityWeighed" value={softwarePart?.quantityWeighed}/> */}
      {/* <FieldValue name="typeApproved" value={softwarePart?.typeApproved}/> */}
      {/* <FieldValue name="cccMarking" value={softwarePart?.cccMarking}/> */}
      {/* <FieldValue name="reqCode" value={softwarePart?.reqCode}/> */}
      {/* <FieldValue name="healthRegulations" value={softwarePart?.healthRegulations}/> */}
      {/* <FieldValue name="homologationNo" value={softwarePart?.homologationNo}/> */}
      <FieldValue name="masteredBy" value={softwarePart?.masteredBy}/>
      {/* <FieldValue name="spareSoftwarePart" value={softwarePart?.spareSoftwarePart}/> */}
      {/* <FieldValue name="alternateId" value={softwarePart?.alternateId}/> */}
      {/* <FieldValue name="colourCode" value={softwarePart?.colourCode}/> */}
      {/* <FieldValue name="colorCode" value={softwarePart?.colorCode}/> */}
      {/* <FieldValue name="colour" value={softwarePart?.colour}/> */}
      {/* <FieldValue name="colourStatus" value={softwarePart?.colourStatus}/> */}
      <FieldValue name="createdAt" value={softwarePart?.createdAt}/>
      <FieldValue name="lastModified" value={softwarePart?.lastModified}/>
    </List>
  );
}
