import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { BullMqJobTableRowFragment } from '../../generated/graphql';
import {
  dateTimeWithSecondsColumn, enumColumn, numberColumn, stringColumn, timeColumn,
} from '../../util/dataGridColumns';

const columns: GridEnrichedColDef<BullMqJobTableRowFragment>[] = [
  {
    ...stringColumn('id'),
    headerName: 'ID',
    sortable: true,
    width: 140,
  },
  {
    ...enumColumn('state'),
    headerName: 'State',
    width: 120,
    sortable: true,
    valueOptions: [
      'completed', 'failed', 'delayed', 'active', 'waiting', 'waiting-children', 'unknown',
    ],
  },
  {
    ...stringColumn('name'),
    headerName: 'Name',
    width: 250,
    sortable: true,
  },
  {
    ...numberColumn('attemptsStarted'),
    headerName: 'AttemptsStarted',
    width: 130,
    sortable: true,
  },
  {
    ...numberColumn('attemptsMade'),
    headerName: 'AttemptsMade',
    width: 120,
    sortable: true,
  },
  {
    ...dateTimeWithSecondsColumn('timestamp'),
    headerName: 'Timestamp',
    sortable: true,
  },
  {
    ...dateTimeWithSecondsColumn('processedOn'),
    headerName: 'ProcessedOn',
    sortable: true,
  },
  {
    ...dateTimeWithSecondsColumn('finishedOn'),
    headerName: 'FinishedOn',
    sortable: true,
  },
  {
    ...timeColumn('queuedTime'),
    headerName: 'QueuedTime',
    sortable: true,
    valueGetter: (v) => {
      // convert 2024-07-09T08:37:04.718Z to integer
      const processedOnTimestamp = v.row.processedOn
        ? new Date(v.row.processedOn).getTime()
        : new Date().getTime(); // default to current time if not processed

      const queuedOnTimestamp = new Date(v.row.timestamp).getTime();

      return processedOnTimestamp - queuedOnTimestamp;
    },
  },
  {
    ...timeColumn('processedTime'),
    headerName: 'ProcessedTime',
    sortable: true,
    valueGetter: (v) => {
      if (!v.row.finishedOn || !v.row.processedOn) {
        return '-';
      }

      const processedOnTimestamp = new Date(v.row.processedOn).getTime();
      const finishedOnTimestamp = new Date(v.row.finishedOn).getTime();

      return finishedOnTimestamp - processedOnTimestamp;
    },
  },
  {
    ...stringColumn('data'),
    headerName: 'data',
    width: 450,
  },
  {
    ...stringColumn('failedReason'),
    headerName: 'FailedReason',
    width: 450,
  },
  {
    field: 'download',
    headerName: 'Download',
    type: 'actions',
    // Note: When using actions you should use getActions instead of renderCell
    // https://stackoverflow.com/questions/75742712/material-ui-datagrid-react-does-not-recognize-the-focuselementref-prop-on-a
    getActions: ({ row }) => ([
      <ButtonGroup key="group">
        <Button
          variant="outlined"
          disabled={row.state !== 'completed'}
          href={row.reportFile || ''}
        >Report</Button>
        <Button
          variant="outlined"
          href={row.importFile || ''}
        >File</Button>
      </ButtonGroup>,
    ]),
    width: 150,
  },
];

interface BullMqJobTableProps {
  jobList: BullMqJobTableRowFragment[];
}

export function BullMqJobTable({ jobList }: BullMqJobTableProps) {
  return (
    <DataGrid
      density="compact"
      loading={false}
      pagination
      getRowHeight={() => 'auto'}
      columns={columns}
      rowCount={jobList.length}
      rows={jobList}
      autoHeight={true}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'timestamp',
              sort: 'desc',
            },
          ],
        },
      }}
      getRowId={(row: BullMqJobTableRowFragment) => row.id}
    />
  );
}
