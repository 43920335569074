import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

export function FieldKey({ children }: PropsWithChildren) {
  return (
    <Typography variant="body2" sx={{
      color: 'rgba(0, 0, 0, 0.6);',
    }}>{children}</Typography>
  );
}
