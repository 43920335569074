import React from 'react';
import {
  Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import ErrorMessage from '../../../../../common/ErrorMessage';
import { useBrowsePlantCodeQuery } from '../../../../../../generated/graphql';

export function PlantCodeSelect(props: { onSelect: (value: number) => void }) {
  const { data, loading, error } = useBrowsePlantCodeQuery();
  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <LinearProgress/>;
  }

  if (!data) {
    return null;
  }

  return (
      <FormControl fullWidth>
        <InputLabel>Plant Code</InputLabel>
        <Select
          label="PlantCode"
          onChange=
          {(event: SelectChangeEvent<number>) => props.onSelect(event.target.value as number)}
          defaultValue={0}
        >
          {data.plants.map((b, i) => (
            <MenuItem key={i} value={b.plantCode}>
              <Box sx={{ display: 'inline-block', width: 120 }}><small>plantCode:</small> {b.plantCode}</Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
