import { ListItem, ListItemText } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { ValueText, ValueTypes } from '../parts/PartDialog/valueText';
import { colors } from '../../../../../services/mui-theme';

interface FieldValueProps {
  name: string,
  value: ValueTypes | Array<ValueTypes>,
}

export function FieldValue({ name, value }: FieldValueProps) {
  return (
    <ListItem sx={{
      borderTop: '1px solid',
      borderTopColor: 'divider',
      alignItems: 'flex-end',
    }}>
      <ListItemText
        primary={ValueText(value)}
        secondary={name}
      />
    </ListItem>
  );
}

export function FieldGrid(props: PropsWithChildren) {
  return (
    <List disablePadding dense sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      borderBottom: '1px solid',
      borderBottomColor: '#e4e4e4',
      '& > *': {
        borderTop: 'none !important',
        borderBottom: '1px solid',
        borderBottomColor: '#e4e4e4',
        marginBottom: '-1px',
      },
    }}>
      {props.children}
    </List>
  );
}

export function FieldGridFlex(props: PropsWithChildren) {
  return (
    <List disablePadding dense sx={{
      display: 'flex',
      flexWrap: 'wrap',
      border: 'none !important',

      '& > *': {
        border: 'none !important',
        flex: 0,
      },
    }}>
      {props.children}
    </List>
  );
}

export function NumberDeleted({ value }: { value: string | number }) {
  if (value === 0) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="error.dark">{value}</Typography>;
}

export function NumberAdded({ value }: { value: string | number }) {
  if (value === 0) {
    return <Typography variant="inherit" color={colors.brand.gray4}>-</Typography>;
  }

  return <Typography variant="inherit" color="info.dark">{value}</Typography>;
}
