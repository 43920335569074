// export function
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { ColoredPaper } from '../../../../../common/style-elements/ColoredPaper';
import { colors } from '../../../../../../services/mui-theme';
import { FieldGridFlex, FieldValue } from '../FieldValue';
import { TypeName } from '../../../../../common/style-elements/TypeNameHeader';
import {
  MfgAssemblyPlaceholderInfoFragment,
  MfgAssemblyInfoFragment,
  MfgEffectivityInfoFragment,
  MfgPartUsageInfoFragment,
} from '../../../../../../generated/graphql';
import { EffectivityList } from './EffectivityList';

export type MfgAssemblyPlaceholder = MfgAssemblyPlaceholderInfoFragment & {
  children?: MfgAssemblyPlaceholderInfoFragment[] | null; // It might have this
  partUsages?: MfgPartUsageInfoFragment[] | null; // It should have this
};

export type PartUsage = MfgPartUsageInfoFragment;

export function MfgAssemblyPlaceholderInfo(props: {
  mfgAssemblyPlaceholder: MfgAssemblyPlaceholder
}) {
  const { mfgAssemblyPlaceholder } = props;

  return (
    <ColoredPaper borderColor={colors.brand.blue4} backgroundColor={'#f4f7fc'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.blue3}>{mfgAssemblyPlaceholder.__typename}</TypeName>
        <FieldValue name="name" value={mfgAssemblyPlaceholder.name}/>
        <FieldValue name="productId" value={mfgAssemblyPlaceholder.productId}/>
      </FieldGridFlex>
      {mfgAssemblyPlaceholder.partUsages?.map((partUsage) => (
        <MfgPartUsageInfo key={partUsage.id} partUsage={partUsage}/>
      ))}
      {mfgAssemblyPlaceholder.children?.map((c) => (
        <MfgAssemblyPlaceholderInfo key={c.id} mfgAssemblyPlaceholder={c}/>
      ))}
    </ColoredPaper>

  );
}

export function MfgAssemblyInfo(props: {
  mfgAssembly: MfgAssemblyInfoFragment
}) {
  const { mfgAssembly } = props;

  return (
    <ColoredPaper borderColor={colors.brand.orange4} backgroundColor={'#fffefc'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.orange3}>{mfgAssembly.__typename}</TypeName>
        <FieldValue name="name" value={mfgAssembly.name}/>
        <FieldValue name="productId" value={mfgAssembly.productId}/>
      </FieldGridFlex>
    </ColoredPaper>
  );
}

export function EffectivitiesPanel(props: {
  effectivities: MfgEffectivityInfoFragment[]
}) {
  return (
    <ColoredPaper borderColor={colors.brand.gray5} backgroundColor={'#fffdfd'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.gray3}>Effectivities</TypeName>
        <ListItem sx={{ flex: 1 }}>
          <EffectivityList effectivities={props.effectivities}/>
        </ListItem>
      </FieldGridFlex>
    </ColoredPaper>
  );
}

export function MfgPartUsageInfo(props: {
  partUsage: PartUsage
}) {
  const { partUsage } = props;

  if (!partUsage) {
    return null;
  }

  return (
    <ColoredPaper backgroundColor={'#fbf7f4'} borderColor={colors.brand.gray4}>
      <FieldGridFlex>
        <TypeName color={colors.brand.gray3}>{partUsage.__typename}</TypeName>
        <FieldValue name="structurePosition" value={partUsage.structurePosition}/>
        <FieldValue name="quantity" value={partUsage.quantity}/>
        <FieldValue name="isSerialized" value={partUsage.isSerialized}/>
        <FieldValue name="isReusable" value={partUsage.isReusable}/>
        <FieldValue name="probability" value={partUsage.probability}/>
      </FieldGridFlex>
      <EffectivitiesPanel effectivities={partUsage.effectivities}/>
      {(partUsage.assembly) && (
        <MfgAssemblyInfo mfgAssembly={partUsage.assembly}/>
      )}
      {(partUsage.part) && (
        <PartInfo part={partUsage.part}/>
      )}
      {(partUsage.mfgPart) && (
        <MfgPartInfo mfgPart={partUsage.mfgPart}/>
      )}
    </ColoredPaper>
  );
}

function PartInfo(props: {
  part: PartUsage['part']
}) {
  const { part } = props;

  return (
    <ColoredPaper borderColor={colors.brand.green4} backgroundColor={'#f9fdf6'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.green3}>{part?.__typename}</TypeName>
        <FieldValue name="name" value={part?.name}/>
        <FieldValue name="partNumber" value={part?.partNumber}/>
      </FieldGridFlex>
    </ColoredPaper>
  );
}

function MfgPartInfo(props: {
  mfgPart: PartUsage['mfgPart']
}) {
  const { mfgPart } = props;

  return (
    <ColoredPaper borderColor={colors.brand.green4} backgroundColor={'#f9fdf6'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.green3}>{mfgPart?.__typename}</TypeName>
        <FieldValue name="name" value={mfgPart?.name}/>
        <FieldValue name="productId" value={mfgPart?.productId}/>
      </FieldGridFlex>
    </ColoredPaper>
  );
}
