import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridEnrichedColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import {
  BrowsePartsQuery, PartWhere, SortDirection, useBrowsePartsQuery,
} from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { PartDialogButton } from './PartDialogButton';
import { convertDataGridFilterToGraphQLWhere } from '../../../../../util/dataGridFilter';
import {
  aggregateColumn, dateTimeColumn, stringColumn,
} from '../../../../../util/dataGridColumns';

type Row = BrowsePartsQuery['parts'][0];

const columns: GridEnrichedColDef<Row>[] = [
  {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (params) => <PartDialogButton where={{ id: params.row.id }} />,
  },
  {
    ...stringColumn('partNumber'),
    headerName: 'Part #',
    width: 100,
    cellClassName: 'font-monospace',
  },
  {
    ...stringColumn('name'),
    width: 210,
  },
  {
    ...stringColumn('project'),
    headerName: 'Project',
    width: 70,
  },
  {
    ...stringColumn('partType'),
    headerName: 'Type',
    width: 60,
  },
  {
    ...stringColumn('partTypeDescription'),
    headerName: 'Type Description',
    width: 180,
  },
  {
    ...stringColumn('masteredBy'),
    headerName: 'Mastered By',
    width: 150,
  },
  {
    ...stringColumn('alternateId'),
    headerName: 'Alternate Id',
    cellClassName: 'font-monospace',
    width: 180,
  },
  {
    ...stringColumn('colourStatus'),
    headerName: 'CS',
    width: 40,
  },
  {
    ...aggregateColumn('assembledByAggregate'),
    headerName: 'Sub parts',
    width: 90,
  },
  {
    ...aggregateColumn('usedInAssembliesAggregate'),
    headerName: 'Is part of',
  },
  {
    ...dateTimeColumn('lastModified'),
    headerName: 'Modified',
    width: 170,
  },
];

export function PartsTable() {
  const pageSize = 100;
  const [page, setPage] = useState<number>(0);
  const [where, setWhere] = useState<PartWhere>({});
  const {
    data, error, loading, previousData,
  } = useBrowsePartsQuery({
    variables: {
      where,
      options: {
        limit: pageSize,
        offset: page * pageSize,
        sort: [{ lastModified: SortDirection.Desc }],
      },
    },
  });

  return (
    <Box mt={2} display="flex" flexDirection="column" height="100%" component={Paper}>
      <ErrorMessage error={error}></ErrorMessage>
      <DataGrid
        density="compact"
        paginationMode="server"
        filterMode="server"
        loading={loading}
        pageSize={pageSize}
        pagination
        // disableColumnMenu
        onFilterModelChange={(filter) => {
          setWhere(convertDataGridFilterToGraphQLWhere(filter, columns));
        }}
        rowsPerPageOptions={[]}
        onPageChange={(p) => setPage(p)}
        columns={columns}
        rowCount={(data || previousData)?.partsAggregate.count || 0}
        rows={(data || previousData)?.parts || []}
      />
    </Box>
  );
}
