import React from 'react';
import {
  Grid, List, Paper, Typography,
} from '@mui/material';
import { ConsumptionRecordDialogQuery } from '../../../../../generated/graphql';
import { MBomInfoTable } from './cr-dialog/MBomInfoTable';
import { FieldValue } from '../common/FieldValue';

type ConsumptionRecordDialogRecord = ConsumptionRecordDialogQuery['consumptionRecords'][0];

interface Props {
  consumptionRecord?: ConsumptionRecordDialogRecord;
}

export function ConsumptionRecordDialogInfoPanel(props: Props) {
  const { consumptionRecord } = props;

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={12} sm={4}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Change Order Issue Added</Typography>
          <List disablePadding>
            <FieldValue name="coiNumber" value={consumptionRecord?.changeOrderIssueAdd?.coiNumber} />
            <FieldValue name="introductionWeek" value={consumptionRecord?.changeOrderIssueAdd?.introductionWeek} />
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>Change Order Issue Deleted</Typography>
          <List disablePadding>
            <FieldValue name="coiNumber" value={consumptionRecord?.changeOrderIssueDel?.coiNumber} />
            <FieldValue name="introductionWeek" value={consumptionRecord?.changeOrderIssueDel?.introductionWeek} />
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h4" p={2}>MBom</Typography>
          <MBomInfoTable consumptionRecord={consumptionRecord}/>
        </Paper>
      </Grid>
    </Grid>
  );
}
