import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AssembledByInfoFragment, PartInfoFragment } from '../../../../../../generated/graphql';
import { DataTreeRow } from '../../../../../common/data/DataTree';
import { PartDialogButton } from '../PartDialogButton';
import { SoftwarePartDialogButton } from '../../software-parts/SoftwarePartDialogButton';

interface Props {
  part?: PartInfoFragment & {
    assembledBy?: AssembledByInfoFragment['assembledBy']
  },
  isTop?: boolean
}

export function ConstituentTree({ part, isTop }: Props) {
  if (!part) {
    return null;
  }

  return (
    <DataTreeRow
      header={
        <Stack direction="row" spacing={2} alignItems="center">
          <span>
            <Box sx={{ margin: '-10px 0 !important' }}>
              {part?.__typename === 'Part' && !isTop
              && <PartDialogButton where={{ id: part.id }} size="small"/>}
              {part?.__typename === 'SoftwarePart' && !isTop
              && <SoftwarePartDialogButton softwarePartWhere={{ id: part.id }} size="small"/>}
            </Box>
          </span>
          <b>{part.partNumber}</b>
          <b>{part.name}</b>
          <b>{part.latestReleasedVersion?.versionId}</b>
          <b>{part.latestReleasedVersion?.preSeriesNumber}</b>
        </Stack>
      }
      suffix={part.__typename}
      defaultExpanded={true}
    >
    {part.assembledBy?.map((c, index) => (
        <DataTreeRow
          key={index}
          header={(
            <Stack direction="row" spacing={2} alignItems="center">
              <span>
                <b>Add:</b> {c.effectiveInWeek} {c.coiNumberAdd && `(${c.coiNumberAdd})`}
              </span>
              <span>
                <b>Del:</b> {c.effectiveOutWeek} {c.coiNumberDel && `(${c.coiNumberDel})`}
              </span>
            </Stack>
          )}
          suffix={c.__typename}
          defaultExpanded={true}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <ConstituentTree part={c.hardwareOrSoftwarePart as any}/>
        </DataTreeRow>
    ))}
    </DataTreeRow>
  );
}
