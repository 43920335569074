import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import React from 'react';
import { FieldKey } from '../../common/FieldKey';
import { FieldKeyValueList } from '../../common/FieldKeyValueList';
import { ReleaseStatusChip } from '../../common/ReleaseStatusChip';

import {
  PartAddressInfoBoxFragment,
} from '../../../../../../generated/graphql';

export function PartAddressInfoBox(props: { partAddress?: PartAddressInfoBoxFragment | null }) {
  const pa = props.partAddress;

  if (!pa) {
    return null;
  }

  return (
    <>
      <Table padding="none" sx={{
        'td ': {
          border: 0, paddingTop: 0, paddingBottom: 0, fontSize: 12,
        },
      }}>
        <TableBody>
          <TableRow>
            <TableCell padding="none"><FieldKey>PA:</FieldKey></TableCell>
            <TableCell>{pa?.number}</TableCell>
            <TableCell>{pa?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none"><FieldKey>POS:</FieldKey></TableCell>
            <TableCell>{pa?.fgPos?.number}</TableCell>
            <TableCell>{pa?.fgPos?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none"><FieldKey>FG:</FieldKey></TableCell>
            <TableCell>{pa?.fgPos?.functionGroup?.number}</TableCell>
            <TableCell>{pa?.fgPos?.functionGroup?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none"><FieldKey>PC:</FieldKey></TableCell>
            <TableCell>{pa?.fgPos?.functionGroup?.bomInstance?.productContext?.typeCode}</TableCell>
            <TableCell>{pa?.fgPos?.functionGroup?.bomInstance?.productContext?.name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box pt={1}>
        <FieldKeyValueList values={{
          'PA Status': <ReleaseStatusChip releaseStatus={pa?.releaseStatus}/>,
        }}/>
      </Box>
    </>
  );
}
