import List from '@mui/material/List';
import React from 'react';
import { PartDialogQuery } from '../../../../../../generated/graphql';
import { FieldValue } from '../../common/FieldValue';

type Part = PartDialogQuery['parts'][0];

interface Props {
  part?: Part
}

export function FieldList({ part }: Props) {
  return (
    <List disablePadding>
      <FieldValue name="partNumber" value={part?.partNumber}/>
      <FieldValue name="name" value={part?.name}/>
      <FieldValue name="assignmentNumber" value={part?.assignmentNumber}/>
      <FieldValue name="project" value={part?.project}/>
      <FieldValue name="docDuty" value={part?.docDuty}/>
      <FieldValue name="unitOfMeasure" value={part?.unitOfMeasure}/>
      <FieldValue name="kuResponsible" value={part?.kuResponsible}/>
      <FieldValue name="taskNumber" value={part?.taskNumber}/>
      <FieldValue name="taskStatus" value={part?.taskStatus}/>
      <FieldValue name="costCarrier" value={part?.costCarrier}/>
      <FieldValue name="designOwner" value={part?.designOwner}/>
      <FieldValue name="description" value={part?.description}/>
      <FieldValue name="partType" value={part?.partType}/>
      <FieldValue name="partTypeDescription" value={part?.partTypeDescription}/>
      <FieldValue name="unit" value={part?.unit}/>
      <FieldValue name="aWeight" value={part?.aWeight}/>
      <FieldValue name="weightType" value={part?.weightType}/>
      <FieldValue name="iWeight" value={part?.iWeight}/>
      <FieldValue name="quantityWeighed" value={part?.quantityWeighed}/>
      <FieldValue name="typeApproved" value={part?.typeApproved}/>
      <FieldValue name="cccMarking" value={part?.cccMarking}/>
      <FieldValue name="reqCode" value={part?.reqCode}/>
      <FieldValue name="healthRegulations" value={part?.healthRegulations}/>
      <FieldValue name="homologationNo" value={part?.homologationNo}/>
      <FieldValue name="masteredBy" value={part?.masteredBy}/>
      <FieldValue name="sparePart" value={part?.sparePart}/>
      <FieldValue name="alternateId" value={part?.alternateId}/>
      <FieldValue name="colourCode" value={part?.colourCode}/>
      <FieldValue name="colour" value={part?.colour}/>
      <FieldValue name="colourStatus" value={part?.colourStatus}/>
      <FieldValue name="createdAt" value={part?.createdAt}/>
      <FieldValue name="lastModified" value={part?.lastModified}/>
    </List>
  );
}
