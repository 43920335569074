/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { UsedInAssembliesInfoFragment, PartInfoFragment } from '../../../../../../generated/graphql';
import { ValueText, ValueTooltip } from './valueText';
import { PartDialogButton } from '../PartDialogButton';
import {SoftwarePartDialogButton} from "../../software-parts/SoftwarePartDialogButton";
import {FieldKeyValueList} from "../../common/FieldKeyValueList";

// type Part = { PartDialogQuery['parts'][0] | SoftwarePartDialogQuery['softwareParts'][0] };

interface Props {
  part?: UsedInAssembliesInfoFragment | null
}

export function UsedInAssembliesTable(props: Props) {
  const { part } = props;

  if (!part) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none"></TableCell>
            <TableCell>POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>hardwareOrSoftwareAssembly</TableCell>
            <TableCell>hardwareOrSoftwareAssembly.latestReleasedVersion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {part.usedInAssemblies.map((c, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="none" sx={{ pl: 1 }}>
                {c.hardwareOrSoftwareAssembly?.__typename === 'Part' &&
                  <PartDialogButton where={{id: c.hardwareOrSoftwareAssembly.id }} size="small"/>}
                {c.hardwareOrSoftwareAssembly?.__typename === 'SoftwarePart' &&
                  <SoftwarePartDialogButton softwarePartWhere={{id:c.hardwareOrSoftwareAssembly.id}} size="small"/>}
              </TableCell>
              <TableCell>{ValueText(c.POST)}</TableCell>
              <TableCell>
                <ValueTooltip title="usedInAssemblies.coiNumberAdd" placement="right" value={c.coiNumberAdd}/><br/>
                <ValueTooltip title="usedInAssemblies.effectiveInWeek" placement="right" value={c.effectiveInWeek}/>
              </TableCell>
              <TableCell>
                <ValueTooltip title="usedInAssemblies.coiNumberDel" placement="right" value={c.coiNumberDel}/><br/>
                <ValueTooltip title="usedInAssemblies.effectiveOutWeek" placement="right" value={c.effectiveOutWeek}/>
              </TableCell>
              <TableCell>
                <FieldKeyValueList values={{
                  type: c.hardwareOrSoftwareAssembly?.__typename,
                  partNumber: c.hardwareOrSoftwareAssembly?.partNumber,
                  name: c.hardwareOrSoftwareAssembly?.name,
                }}/>
              </TableCell>
              <TableCell>
                <FieldKeyValueList values={{
                  versionId: (c.hardwareOrSoftwareAssembly)?.latestReleasedVersion?.versionId,
                  preSeriesNumber: (c.hardwareOrSoftwareAssembly)?.latestReleasedVersion?.preSeriesNumber,
                }}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
