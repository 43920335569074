import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTeamCenterBomUploadMutation } from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { SectionCardHeader } from '../../common/SectionCardHeader';
import { Section } from '../../layout/Section';

export function UploadBom() {
  return (
    <>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Upload upper BOM"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              This will populate ProductFamilyInPlant, Arts, FunctionGroups,
              FgPoses and PartAddresses. <br/>
              Note that this does not delete any nodes. <br/>
            </Typography>
            <TeamCenterBomUpload
              type="UpperBom"
              buttonText="Upload upper Bom plmxml"
            />
          </CardContent>
        </Card>
      </Section>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Lower BOM upload"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              This will populate Part, Version,Folders,Documents,
              Constituents, Usages , Consumptions,Change Order and PartAddresses.
              This will also revoke PartUsages and Constituents where applicable <br/>
              <br/>
            </Typography>
            <TeamCenterBomUpload
              type="LowerBom"
              buttonText="Upload Lower Bom plmxml"
            /> </CardContent>
        </Card>
      </Section>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Lower Assignments BOM upload"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              This will populate custom Assignment information for Parts <br/>
              <br/>
            </Typography>
            <TeamCenterBomUpload
              type="LowerBomAssignments"
              buttonText="Upload Lower Assignments Bom plmxml"
            /> </CardContent>
        </Card>
      </Section>

      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Bulk PartVersion BOM upload"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              Will bulk upload partVersions <br/>
              <br/>
            </Typography>
            <TeamCenterBomUpload
              type="BulkPartVersion"
              buttonText="Upload bulk BulkPartVersion Bom plmxml"
            /> </CardContent>
        </Card>
      </Section>

      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Spare part info upload"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              Will upload Spare part info <br/>
              <br/>
            </Typography>
            <TeamCenterBomUpload
              type="SparePartInfo"
              buttonText="Upload Spare part info plmxml"
            /> </CardContent>
        </Card>
      </Section>

      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Upload BOP/Mfg"
          />
          <CardContent>
            <Typography color="text.secondary" mb={2} variant="body2">
              This will populate Bop and Mfg
            </Typography>
            <TeamCenterBomUpload
              type="BopMfg"
              buttonText="Upload BOP/Mfg plmxml"
            />
          </CardContent>
        </Card>
      </Section>
      <Section>
        <Card>
          <SectionCardHeader
            avatar={<ArrowUpward color="secondary"/>}
            title="Upload StructureCheck"
          />
          <CardContent>
            <Alert severity="warning">
              currently hard coded for typeCode 4101 (topNode 200) and dictionary 41
            </Alert>
            <Typography color="text.secondary" mb={2} variant="body2">
              This will set expected quantity on part addresses
            </Typography>
            <TeamCenterBomUpload
              type="StructureCheck"
              buttonText="Upload StructureCheck plmxml"
            />
          </CardContent>
        </Card>
      </Section>
    </>
  );
}

interface UploadProps {
  type: 'UpperBom' |
  'LowerBom' |
  'BopMfg' |
  'LowerBomAssignments' |
  'StructureCheck' |
  'BulkPartVersion' |
  'SparePartInfo',
  buttonText: string,
}

export function TeamCenterBomUpload(props: UploadProps) {
  const { type, buttonText } = props;
  const [callUpload, { data, loading, error }] = useTeamCenterBomUploadMutation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = e.target;

    if (validity.valid && files) {
      callUpload({ variables: { file: files[0], type } }).then(() => {
        // reset the value so we can select the same file again
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      });
    }
  };

  return (
    <>
      <ErrorMessage error={error}/>

      <label htmlFor={`${type}-upload-input`}>
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          accept="text/xml"
          required
          id={`${type}-upload-input`}
          type="file"
          onChange={onChange}
        />
        <LoadingButton
          variant="contained"
          component="span"
          loading={loading}
        >
          {buttonText}
        </LoadingButton>
      </label>
      {data && (
        <pre>{data.teamCenterBomUpload.result}</pre>
      )}
    </>
  );
}
