// General handler for graph ql errors
import './ErrorMessage.scss';

import { ApolloError } from '@apollo/client';
import React from 'react';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  error: ApolloError | undefined,
}

export default function ErrorMessage({ error }: Props) {
  if (!error) {
    return null;
  }

  if (error.networkError?.message === 'Failed to fetch') {
    return (
      <Alert severity="error" className='error-message'>
        <Box display="inline-block">
          Unable to contact the PRINS GraphHub API.&nbsp;
        </Box>
        <Box display="inline-block">
          Are you on the Volvo network?
        </Box>
      </Alert>
    );
  }

  if (error.graphQLErrors[0]?.extensions?.code === 'INTERNAL_SERVER_ERROR'
    && error.graphQLErrors[0].message === 'Forbidden') {
    return (
      <Alert severity="error" className='error-message'>
        <Box display="inline-block">
          You don´t have permission to view this information.&nbsp;
        </Box>
        <Box display="inline-block">
          Are you assigned to an application?
        </Box>
      </Alert>
    );
  }

  return (
    <Alert severity="error" className='error-message'>
      {error.graphQLErrors.length === 0 ? error.message : null}
      {error.graphQLErrors.map((e, i) => (
        <pre key={i}>{e.message}</pre>
      ))}
    </Alert>
  );
}
