import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox, FormControlLabel, MenuItem, Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GraphiQLProvider, QueryEditor, VariableEditor } from '@graphiql/react';
import Grid from '@mui/material/Grid';
import { PropsWithChildren, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  ApplicationDetailsForOperationFragment, ApplicationOperationInput, OperationDetailsFragment,
  useSaveApplicationOperationMutation,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';
import { ReactHookFormSelect } from '../../common/ReactHookFormSelect';
import { useFetcher } from '../../common/GraphqlCodeBlock/useFetcher';
import { colors } from '../../../services/mui-theme';
import { ApplicationOperationRunButton } from './ApplicationOperationRunButton';

interface Props {
  application: ApplicationDetailsForOperationFragment;
  operation?: OperationDetailsFragment;
  onComplete: () => void;
}

type IFormInput = ApplicationOperationInput;

const helpTexts = {
  query: "Enter the GraphQL query you're using in your application. Ensure it's formatted correctly.",
  variables: 'Variables used with the query. Provide example values for testing purposes.',
  description: "Provide a brief overview of the query's purpose and what it aims to achieve.",
  consequence: 'Describe what will happen in your application if you cannot reach our servers.',
  impactLevel: "Rate the significance of the integration on a scale (e.g., Low, Medium, High, Critical). How vital is our service to your app's functionality?",
  frequency: "Indicate how often you'll be executing this query. E.g., hourly, daily, on-demand.",
  trigger: 'Specify the event or condition that will initiate the execution of this query in your application.',
  draft: 'Check to mark as draft. Operations marked as draft will not be included in any test performed by the PRINS team.',
};

export function ApplicationOperationForm(props: Props) {
  const { application } = props;
  const [call, { loading, error }] = useSaveApplicationOperationMutation();
  const fetcher = useFetcher();
  const [query, setQuery] = useState<string>(props.operation?.query || '');
  const [variables, setVariables] = useState<string>(props.operation?.variables || '');

  const {
    register, control, handleSubmit, setValue, reset,
  } = useForm<IFormInput>({
    defaultValues: {
      id: props.operation?.id || undefined,
      appId: application.appId,
      query: props.operation?.query || '',
      variables: props.operation?.variables || '',
      consequence: props.operation?.consequence || '',
      description: props.operation?.description || '',
      frequency: props.operation?.frequency || '',
      impactLevel: props.operation?.impactLevel || '',
      trigger: props.operation?.trigger || '',
      draft: props.operation?.draft || false,
    },
  });

  const isNew = !props.operation?.id;

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    await call({
      variables: {
        input: {
          ...formData,
        },
      },
    });

    props.onComplete();

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{isNew ? 'New' : 'Edit'} operation for {application.appName}</DialogTitle>
      <DialogContent>
        <ErrorMessage error={error}/>
        {props.operation?.adminNotes && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <strong>Notes from PRINS-team</strong><br/>
            {props.operation?.adminNotes}
          </Alert>
        )}

        <GraphiQLProvider
          fetcher={fetcher}
          query={props.operation?.query || ''}
          variables={props.operation?.variables || ''}
        >
          <Grid container direction="row-reverse" spacing={3}>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.query}</HelpText>
              <HelpText>Ctrl+Shift+P: Format code</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Paper variant="outlined">
                <EditorLabel>GraphQL Query:</EditorLabel>
                <EditorWrapper height={360}>
                  <QueryEditor onEdit={(value) => {
                    setQuery(value);
                    setValue('query', value);
                  }}/>
                </EditorWrapper>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.variables}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Paper variant="outlined">
                <EditorLabel>GraphQL Variables (JSON):</EditorLabel>
                <EditorWrapper height={140}>
                  <VariableEditor onEdit={(value) => {
                    setVariables(value);
                    setValue('variables', value);
                  }}/>
                </EditorWrapper>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <ApplicationOperationRunButton
              query={query}
              variables={variables}/>
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.description}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                margin="dense"
                label="Description*"
                fullWidth
                multiline
                minRows={2}
                {...register('description', { required: true })}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.impactLevel}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <ReactHookFormSelect
                label="Impact Level*"
                name="impactLevel"
                control={control}
                rules={{ required: true }}
                variant="outlined"
                margin="dense"
              >
                <MenuItem value="critical">
                  <strong>Critical: </strong> &nbsp;
                  Application cannot function without the integration.
                </MenuItem>
                <MenuItem value="high">
                  <strong>High: </strong> &nbsp;
                  Application functions but loses major features or efficiency.
                </MenuItem>
                <MenuItem value="medium">
                  <strong>Medium: </strong> &nbsp;
                  Some features are compromised without the integration,
                  but core functionality remains.
                </MenuItem>
                <MenuItem value="low">
                  <strong>Low: </strong> &nbsp;
                  The integration enhances the application but isn&apos;t
                  essential to its core functions.
                </MenuItem>
              </ReactHookFormSelect>
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.consequence}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                margin="dense"
                label="Consequence"
                fullWidth
                multiline
                {...register('consequence')}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.frequency}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                margin="dense"
                label="Frequency"
                fullWidth
                multiline
                {...register('frequency')}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.trigger}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                margin="dense"
                label="Trigger"
                fullWidth
                multiline
                {...register('trigger')}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <HelpText>{helpTexts.draft}</HelpText>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                control={control}
                name={'draft'}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label="Draft"
                    control={
                      <Checkbox checked={value as boolean} onChange={onChange} />
                    }
                  />
                )}
              />
            </Grid>

          </Grid>
        </GraphiQLProvider>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onComplete()}>Cancel</Button>
        <LoadingButton type="submit" variant="contained" loading={loading}>{isNew ? 'Create' : 'Save'}</LoadingButton>
      </DialogActions>
    </form>
  );
}

function HelpText(props: PropsWithChildren) {
  return (
    <Typography
      variant="body2"
      pt={1}
      px={1}
      pb={2}
      sx={{ borderLeft: `4px solid ${colors.brand.blue3}` }}
    >{props.children}</Typography>
  );
}

function EditorWrapper(props: PropsWithChildren<{ height: number }>) {
  return (
    <div className="graphiql-container">
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        minHeight: props.height,
        '& > *': { flex: 1 },
        '& .CodeMirror-scroll': { background: '#fff' },
      }}>{props.children}</Box>
    </div>
  );
}

function EditorLabel(props: PropsWithChildren) {
  return (
    <Typography
      variant="body1"
      sx={{
        m: 1.5,
        mb: 0,
        color: 'gray',
        background: '#fff',
      }}
    >{props.children}</Typography>
  );
}
