import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { OperationDetailsFragment } from '../../../generated/graphql';
import { FieldGrid, FieldValue } from '../../documentation/pages/browser/common/FieldValue';
import { dateTimeFormat } from '../../../util/date';

export function OperationDetails(props: { operation: OperationDetailsFragment }) {
  const { operation } = props;

  return (
    <Box>
      <Paper variant="outlined">
        <FieldGrid>
          <FieldValue name="createdAt" value={dateTimeFormat(operation.createdAt)} />
          <FieldValue name="lastModified" value={dateTimeFormat(operation.lastModified)} />
          <FieldValue name="approvedAt" value={dateTimeFormat(operation.approvedAt)} />
          <FieldValue name="createdByUser" value={operation.createdByUser?.name} />
          <FieldValue name="modifiedByUser" value={operation.modifiedByUser?.name} />
          <FieldValue name="approvedByUser" value={operation.approvedByUser?.name} />
          <FieldValue name="description" value={operation.description} />
          <FieldValue name="consequence" value={operation.consequence} />
          <FieldValue name="impactLevel" value={operation.impactLevel} />
          <FieldValue name="frequency" value={operation.frequency} />
          <FieldValue name="trigger" value={operation.trigger} />
          <FieldValue name="draft" value={operation.draft} />
        </FieldGrid>
      </Paper>
    </Box>
  );
}
