import TableCell from '@mui/material/TableCell';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { FieldKey } from './FieldKey';

interface FieldKeyValueListProps {
  values: {
    [key: string]: string | number | null | undefined | boolean | React.ReactElement
  }
}

export function FieldKeyValueList({ values }: FieldKeyValueListProps) {
  return (
    <Table padding="none" sx={{ width: 'auto', td: { border: 'none' } }}>
      <TableBody>
        {Object.keys(values).map((key, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
          >
            <TableCell>
              <FieldKey>{key}:</FieldKey>
            </TableCell>
            <TableCell sx={{ pl: 1.5 }}>
              {values[key]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
