/* eslint-disable max-len */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import { PartDialogQuery, SoftwarePartDialogQuery } from '../../../../../../generated/graphql';
import { ValueText, ValueTooltip } from './valueText';
import { FieldKeyValueList } from '../../common/FieldKeyValueList';
import { PartAddressInfoBox } from '../../bom/common/PartAddressInfoBox';

type Part = PartDialogQuery['parts'][0] | SoftwarePartDialogQuery['softwareParts'][0];

interface Props {
  part?: Part | null
}

export function PartUsagesTable(props: Props) {
  const { part } = props;

  if (!part) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>Bom Line</TableCell>
            <TableCell>Usage Rules</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {part.partUsages.map((pu, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>{ValueText(pu.POST)}</TableCell>
              <TableCell>
                <ValueTooltip title="partUsage.coiNumberAdd" placement="right" value={pu.coiNumberAdd}/><br/>
                <ValueTooltip title="partUsage.effectiveInWeek" placement="right" value={pu.effectiveInWeek}/>
              </TableCell>
              <TableCell>
                <ValueTooltip title="partUsage.coiNumberDel" placement="right" value={pu.coiNumberDel}/><br/>
                <ValueTooltip title="partUsage.effectiveOutWeek" placement="right" value={pu.effectiveOutWeek}/>
              </TableCell>
              <TableCell>
                <PartAddressInfoBox partAddress={pu.partAddress}/>
              </TableCell>
              <TableCell>
                {pu.usageRules.map((ur) => (
                  <Box key={ur.id} sx={{
                    '&:not(:first-child)': { mt: 1, pt: 1, borderTop: '1px solid #ddd' },
                  }}>
                    <FieldKeyValueList values={{
                      pmlId: ur.pmlId,
                      varCodes: ur.varCodes,
                      typeCode: ur.typeCode,
                    }}/>
                  </Box>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
