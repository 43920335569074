import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { PartDialogButton } from '../../parts/PartDialogButton';
import { SoftwarePartDialogButton } from '../../software-parts/SoftwarePartDialogButton';
import {
  ConsumptionRecordMbomDataFragment,
} from '../../../../../../generated/graphql';
import { LmodDialogButton } from '../../lmod/LmodDialogButton';

interface ConsumptionsRecordsTableProps {
  consumptionRecords: ConsumptionRecordMbomDataFragment[]
}

export function ConsumptionsRecordsTable(props: ConsumptionsRecordsTableProps) {
  const { consumptionRecords } = props;

  return (
    <Box mb={1}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>POST</TableCell>
              <TableCell>quantity</TableCell>
              <TableCell>partNumber</TableCell>
              <TableCell>effectiveInWeek</TableCell>
              <TableCell>effectiveOutWeek</TableCell>
              <TableCell>coiNumberAdd</TableCell>
              <TableCell>coiNumberDel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consumptionRecords.map((cr, index) => (
              <TableRow
                key={index}
                className={cr.isRevoked ? 'revoked' : ''}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  // Strikethrough and change opacity when isRevoked
                  '&.revoked': {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  },
                }}
              >
                <TableCell>{cr.POST}</TableCell>
                <TableCell>{cr.quantity}</TableCell>
                <TableCell>
                  {/* <PartDialogButton
                    where={{ partNumber: pu.hardwareOrSoftwarePart?.partNumber }}
                  /> */}
                  <Tooltip title={cr.partUsage.hardwareOrSoftwarePart?.name || cr.partUsage.deliveryModule?.name || 'Missing part/lmod'}>
                    <Box mr={1} component="span">
                      {cr.partUsage.hardwareOrSoftwarePart?.partNumber}
                      {cr.partUsage.deliveryModule?.partNumber}
                    </Box>
                  </Tooltip>
                  <Chip label={cr.partUsage.hardwareOrSoftwarePart?.__typename || cr.partUsage.deliveryModule?.__typename} variant="outlined" size="small" color={cr.partUsage.hardwareOrSoftwarePart?.__typename === 'Part' ? 'info' : 'warning'}/>
                  {cr.partUsage.hardwareOrSoftwarePart?.__typename === 'Part' && (
                    <PartDialogButton where={{ partNumber: cr.partUsage.hardwareOrSoftwarePart.partNumber }} size="small"/>
                  )}
                  {cr.partUsage.hardwareOrSoftwarePart?.__typename === 'SoftwarePart' && (
                    <SoftwarePartDialogButton softwarePartWhere={{ partNumber: cr.partUsage.hardwareOrSoftwarePart.partNumber }} size="small"/>
                  )}
                  {cr.partUsage.deliveryModule && (
                    <LmodDialogButton where={{ id: cr.partUsage.deliveryModule.id }} size="small" />
                  )}
                </TableCell>
                <TableCell>{cr.effectiveInWeek}</TableCell>
                <TableCell>{cr.effectiveOutWeek}</TableCell>
                <TableCell>{cr.changeOrderIssueAdd?.coiNumber || 'NA'}</TableCell>
                <TableCell>{cr.changeOrderIssueDel?.coiNumber || 'NA'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
