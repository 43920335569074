/* eslint-disable */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { LmodDialogQuery } from '../../../../../../generated/graphql';
import { ValueTooltip } from '../../parts/PartDialog/valueText';

type Lmod = LmodDialogQuery['deliveryModules'][0];

interface Props {
  lmod?: Lmod | null
}

export function LmodConstituentTable(props: Props) {
  const { lmod } = props;

  if (!lmod?.dModuleConstituents) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none"></TableCell>
            <TableCell>Function Group</TableCell>
            <TableCell>Pos</TableCell>
            <TableCell>Type Code</TableCell>
            <TableCell>Effective in week</TableCell>
            <TableCell>Effective out week</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lmod.dModuleConstituents.map((dmodC, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="none" sx={{ pl: 1 }}>
              </TableCell>
              <TableCell>
                <ValueTooltip title="dModuleConstituent.fgPos.number" placement="right" value={dmodC.fgPos.functionGroup.number} />
              </TableCell>
              <TableCell>
                <ValueTooltip title="dModuleConstituent.fgPos.number" placement="right" value={dmodC.fgPos.number} />
              </TableCell>
              <TableCell>
                <ValueTooltip title="dModuleConstituent.fgPos.functionGroup.bomInstance.productContext.typeCode" placement="right" value={dmodC.fgPos.functionGroup.bomInstance.productContext?.typeCode} />
              </TableCell>
              <TableCell>
                <ValueTooltip title="dModuleConstituent.effectiveInWeek" placement="right" value={dmodC.effectiveInWeek} />
              </TableCell>
              < TableCell>
                <ValueTooltip title="dModuleConstituent.effectiveOutWeek" placement="right" value={dmodC.effectiveOutWeek} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
