/* eslint-disable max-len */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { UsageRuleDialog_PartUsageFragment } from '../../../../../../generated/graphql';
import { ValueText, ValueTooltip } from './valueText';
import { PartUsagePartButton } from '../../bom/common/PartUsagePartButton';
import { PartAddressInfoBox } from '../../bom/common/PartAddressInfoBox';

interface Props {
  partUsages?: UsageRuleDialog_PartUsageFragment[]
}

export function PartUsagesTable(props: Props) {
  const { partUsages } = props;

  if (!partUsages) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>Bom Line</TableCell>
            <TableCell>Part / LMOD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partUsages.map((pu, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>{ValueText(pu.POST)}</TableCell>
              <TableCell>
                <ValueTooltip title="partUsage.coiNumberAdd" placement="right" value={pu.coiNumberAdd}/><br/>
                <ValueTooltip title="partUsage.effectiveInWeek" placement="right" value={pu.effectiveInWeek}/>
              </TableCell>
              <TableCell>
                <ValueTooltip title="partUsage.coiNumberDel" placement="right" value={pu.coiNumberDel}/><br/>
                <ValueTooltip title="partUsage.effectiveOutWeek" placement="right" value={pu.effectiveOutWeek}/>
              </TableCell>
              <TableCell>
                <PartAddressInfoBox partAddress={pu.partAddress}/>
              </TableCell>
              <TableCell>
                <PartUsagePartButton partUsage={pu}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
