import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React from 'react';
import { PartDialogButton } from '../../parts/PartDialogButton';
import { SoftwarePartDialogButton } from '../../software-parts/SoftwarePartDialogButton';
import { LmodDialogButton } from '../../lmod/LmodDialogButton';

import {
  PartUsagePartButtonFragment,
} from '../../../../../../generated/graphql';

export function PartUsagePartButton(props: { partUsage: PartUsagePartButtonFragment }) {
  const pu = props.partUsage;

  return (
    <>
      <Tooltip title={pu.hardwareOrSoftwarePart?.name || pu.deliveryModule?.name || 'Missing part/lmod'}>
        <Box mr={1} component="span">
          {pu.hardwareOrSoftwarePart?.partNumber}
          {pu.deliveryModule?.partNumber}
        </Box>
      </Tooltip>
      <Chip
        label={pu.hardwareOrSoftwarePart?.__typename || pu.deliveryModule?.__typename}
        variant="outlined"
        size="small" color={pu.hardwareOrSoftwarePart?.__typename === 'Part' ? 'info' : 'warning'}
      />
      {pu.hardwareOrSoftwarePart?.__typename === 'Part' && (
        <PartDialogButton where={{ partNumber: pu.hardwareOrSoftwarePart.partNumber }} size="small"/>
      )}
      {pu.hardwareOrSoftwarePart?.__typename === 'SoftwarePart' && (
        <SoftwarePartDialogButton
          softwarePartWhere={{ partNumber: pu.hardwareOrSoftwarePart.partNumber }}
          size="small"
        />
      )}
      {pu.deliveryModule && (
        <LmodDialogButton where={{ id: pu.deliveryModule.id }} size="small"/>
      )}
    </>
  );
}
