import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  PartUsageEbomDataFragment,
} from '../../../../../../generated/graphql';
import { PartUsagePartButton } from '../common/PartUsagePartButton';

interface PartUsageTableProps {
  partUsages: PartUsageEbomDataFragment[]
}

export function PartUsageTable(props: PartUsageTableProps) {
  const { partUsages } = props;

  return (
    <Box mb={1}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>POST</TableCell>
              <TableCell>quantity</TableCell>
              <TableCell>partNumber</TableCell>
              <TableCell>effectiveInWeek</TableCell>
              <TableCell>effectiveOutWeek</TableCell>
              <TableCell>coiNumberAdd</TableCell>
              <TableCell>coiNumberDel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partUsages.map((pu, index) => (
              <TableRow
                key={index}
                className={pu.isRevoked ? 'revoked' : ''}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  // Strikethrough and change opacity when isRevoked
                  '&.revoked': {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  },
                }}
              >
                <TableCell>{pu.POST}</TableCell>
                <TableCell>{pu.quantity}</TableCell>
                <TableCell>
                  <PartUsagePartButton partUsage={pu} />
                </TableCell>
                <TableCell>{pu.effectiveInWeek}</TableCell>
                <TableCell>{pu.effectiveOutWeek}</TableCell>
                <TableCell>{pu.coiNumberAdd}</TableCell>
                <TableCell>{pu.coiNumberDel}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
