import React, { useEffect, useRef } from 'react';
import timelines, { Group, Segment, TimelinesChartInstance } from 'timelines-chart';

export interface Job {
  id: string;
  name: string;
  timestamp: string;
  processedOn: string | null;
  finishedOn: string | null;
}

interface TimelineChartProps {
  jobs: Job[];
}

const TimelineChart: React.FC<TimelineChartProps> = ({ jobs }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const instanceRef = useRef<TimelinesChartInstance | null>(null); // To store the chart instance

  useEffect(() => {
    if (chartRef.current && !instanceRef.current) {
      // Create the initial chart instance
      instanceRef.current = timelines()
        .zQualitative(true)
        .zScaleLabel('Job Timeline')
        .width(1200);

      // Append the chart to the DOM element
      instanceRef.current(chartRef.current);
    }
  }, []);

  useEffect(() => {
    if (instanceRef.current && jobs.length > 0) {
      const sortedJobs = [...jobs].sort(
        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      );

      // Transform the job data into the format expected by timelines-chart
      const chartData: Group[] = [
        {
          group: 'Jobs',
          data: sortedJobs.map((job) => {
            const segments: Segment[] = [
              {
                timeRange: [
                  new Date(job.timestamp),
                  job.processedOn ? new Date(job.processedOn) : new Date(),
                ],
                val: 'Queued',
              },
            ];

            if (job.finishedOn && job.processedOn) {
              segments.push({
                timeRange: [new Date(job.processedOn), new Date(job.finishedOn)],
                val: 'Execution',
              });
            }

            return {
              label: `${job.id} ${job.name}`,
              data: segments,
            };
          }),
        },
      ];

      // Update the chart with the new data
      instanceRef.current.data(chartData);
    }
  }, [jobs]);

  return <div ref={chartRef}></div>;
};

export default TimelineChart;
