import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { LabelConfigInfoFragment } from '../../../../../../generated/graphql';
import { DataTreeRow } from '../../../../../common/data/DataTree';

interface Props {
  config?: LabelConfigInfoFragment;
}

export function LabelConfigTree(props: Props) {
  const { config } = props;

  if (!config) {
    return null;
  }

  return (
    <DataTreeRow
      header={
        <Stack direction="row" spacing={2} alignItems="center">
          <b>{config.code}</b>
          <span>{config.name}</span>
          <span>{config.description}</span>
          <span>{config.labelHeight}</span>
        </Stack>
      }
      suffix={config.__typename}
      defaultExpanded={true}
    >
      {config.positions?.map((p, index) => (
        <DataTreeRow
          key={index}
          header={(
            <Stack direction="row" spacing={2} alignItems="center">
              <b>{p.code}</b>
              <KeyValue label="name" value={p.name} />
              <KeyValue label="description" value={p.description} />
              <KeyValue label="max digits" value={p.maximumNumberOfDigits} />
              <KeyValue label="labelHeight" value={p.labelHeight} />
              <KeyValue label="position" value={p.position} />
            </Stack>
          )}
          suffix={p.__typename}
          defaultExpanded={true}
        >
          {p.values?.map((v, index2) => (
            <DataTreeRow
              key={index2}
              header={(
                <Stack direction="row" spacing={2} alignItems="center">
                  <b>{v.code}</b>
                  <KeyValue label="name" value={v.name} />
                  <KeyValue label="description" value={v.description} />
                  <KeyValue label="indicator" value={v.indicator} />
                </Stack>
              )}
              suffix={v.__typename}
              defaultExpanded={true}
            >
            </DataTreeRow>
          ))}
        </DataTreeRow>
      ))}
    </DataTreeRow>
  );
}

function KeyValue({ label, value }: { label: string, value?: string | number | null }) {
  if (value === null || value === undefined) {
    return null;
  }

  return (
    <Box>
      <Box component="span" sx={{
        opacity: 0.4,
        fontSize: '0.8em',
      }}>{label}:</Box> {value}
    </Box>
  );
}
