import List from '@mui/material/List';
import React from 'react';
import { LmodDialogQuery } from '../../../../../../generated/graphql';
import { FieldValue } from '../../common/FieldValue';

type LMOD = LmodDialogQuery['deliveryModules'][0];

interface Props {
  lmod?: LMOD
}

export function FieldList({ lmod: part }: Props) {
  return (
    <List disablePadding>
      <FieldValue name="partNumber" value={part?.partNumber}/>
      <FieldValue name="name" value={part?.name}/>
      <FieldValue name="assignmentNumber" value={part?.assignmentNumber}/>
      <FieldValue name="description" value={part?.description}/>
      <FieldValue name="unit" value={part?.unit}/>
      <FieldValue name="masteredBy" value={part?.masteredBy}/>
      <FieldValue name="createdAt" value={part?.createdAt}/>
      <FieldValue name="lastModified" value={part?.lastModified}/>
    </List>
  );
}
